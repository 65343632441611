<template>
  <div
    class="chat-list-item d-flex align-center px-0 px-md-4 py-4"
    :class="{ 'chat-list-item--active primary': isActive }"
    v-on="$listeners"
  >
    <v-avatar class="mr-2" size="40" color="secondary">
      <v-icon :class="{ white: isActive }" color="primary">mdi-account</v-icon>
    </v-avatar>

    <div class="chat-list-item__units-container d-flex flex-column">
      <span class="text-caption font-weight-medium" :class="{ 'white--text': isActive }">{{ name }}</span>
      <span class="chat-list-item__units-list text-caption" :class="{ 'chat-list-item__units-list--active': isActive }">
        {{ unitsList }}
      </span>
    </div>

    <v-spacer />

    <div
      v-if="canDisplayMessageCount"
      class="chat-list-item__unread-count-wrapper d-flex align-center justify-center rounded-circle primary"
    >
      <span class="chat-list-item__unread-count white--text text-body-2">{{ unreadCount }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatListItem',

  props: {
    name: { type: String, required: true },
    unreadCount: { type: Number, default: 0 },
    isActive: { type: Boolean, default: false },
    units: { type: Array, default: () => [] },
  },

  computed: {
    canDisplayMessageCount() {
      return !this.isActive && this.unreadCount > 0;
    },

    unitsList() {
      return this.units.map(unit => unit.name).join(', ');
    },
  },
};
</script>

<style lang="scss">
$avatar-size: 48px;

.chat-list-item {
  cursor: pointer;

  &--active {
    box-shadow: $--shadow-main;
  }

  &__unread-count-wrapper {
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
  }

  &__unread-count {
    margin-top: 1px;
    margin-left: 1px;

    @media (max-width: map.get($--screens, 'md')) {
      margin-right: 0;
    }
  }

  &__units-list {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    opacity: 0.5;
    color: $--black-color-0;

    &--active {
      color: $--white-color-0;
    }
  }

  &__units-container {
    max-width: calc(100% - #{$avatar-size});
  }
}
</style>
