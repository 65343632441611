<template>
  <div class="chat-body d-flex flex-column justify-end">
    <slot>
      <v-spacer />
      <v-container ref="body" class="chat-body__inner px-lg-8 pb-lg-8 px-sm-2 pb-sm-4" fluid>
        <div v-intersect="fetchMessages"></div>
        <v-row v-for="message in messagesWithTranslationModel" :key="message.id">
          <v-col class="py-4" :class="{ 'ml-auto': checkOwner(message) }" :lg="getCols(message)" sm="12">
            <chat-message
              :name="getName(message)"
              :text="message.text"
              :message-id="message.id"
              :dark="checkOwner(message)"
              :date="message.date"
              :attached-files="message.attachedFiles"
              :avatar-position="getAvatarPosition(message)"
              :is-online="message.isOnline"
              :translated-text="message.translatedText"
              :is-loading-translate="message.isLoadingTranslate"
              @translate-message="translateMessage"
            />
          </v-col>
        </v-row>
      </v-container>
    </slot>
  </div>
</template>

<script>
// node_modules
import { mapState } from 'vuex';

// Services
import chatService from '@/services/chat';

// Components
import ChatMessage from '@/components/Chat/Message.vue';

// Utils
import { isEqual } from '@/utils/common';

export default {
  name: 'ChatBody',

  components: { ChatMessage },

  inject: ['media'],

  props: {
    messages: { type: Array, default: () => [] },
    chatId: { type: Number, default: 0 },
  },

  data() {
    return {
      scrollHeight: 0,
      newRoom: true,
      translatedMessagesModels: {},
    };
  },

  computed: {
    ...mapState({
      localeId: state => state.locale.localeModel.instance.id,
    }),

    hasMessages() {
      return this.messages.length > 0;
    },

    messagesWithTranslationModel() {
      return this.messages.map(message => ({
        ...message,
        translatedText: this.translatedMessagesModels[message.id]?.text || '',
        isLoadingTranslate: this.translatedMessagesModels[message.id]?.isLoadingTranslate || false,
      }));
    },
  },

  watch: {
    messages(newMessages, oldMessages) {
      if (isEqual(newMessages, oldMessages)) return;

      this.$nextTick(() => {
        if (this.newRoom) {
          this.scrollToBottom?.();
          this.scrollHeight = 0;
          this.newRoom = false;
          return;
        }

        const ref = this.$refs.body;

        if (!ref) return;

        const { scrollHeight } = ref;
        const scrollFix = scrollHeight - this.scrollHeight;

        ref.scrollTop += scrollFix;
        this.scrollHeight = scrollHeight;
      });
    },

    chatId() {
      this.newRoom = true;
    },
  },

  mounted() {
    chatService.socket.on('translatedMessage', this.onMessageTranslated);
    this.scrollToBottom?.();
  },

  beforeDestroy() {
    chatService.socket.removeListener('translatedMessage', this.onMessageTranslated);
  },

  methods: {
    getCols(message) {
      return this.checkOwner(message) ? '10' : '8';
    },

    getAvatarPosition(message) {
      return this.checkOwner(message) ? 'right' : 'left';
    },

    getName(message) {
      return this.checkOwner(message) ? this.$t('chat.me') : message.name;
    },

    scrollToBottom() {
      if (this.$refs?.body?.scrollTop) {
        this.$refs.body.scrollTop = this.$refs.body.scrollHeight;
      }
    },

    checkOwner(message) {
      return message.name.toLowerCase() === 'me';
    },

    // eslint-disable-next-line func-names
    fetchMessages(event) {
      if (event[0]?.isIntersecting) {
        this.$emit('fetch-messages');
      }
    },

    onMessageTranslated({ translated, messageId }) {
      this.$set(this.translatedMessagesModels, messageId, { text: translated, isLoadingTranslate: false });
    },

    translateMessage(messageId) {
      this.$set(this.translatedMessagesModels, messageId, {
        ...this.translatedMessagesModels[messageId],
        isLoadingTranslate: true,
      });

      chatService.translateMessage(messageId, this.localeId);
    },
  },
};
</script>

<style lang="scss">
.chat-body {
  background: rgba($--chat-body-background, 0.4);
  position: relative;

  &__inner {
    position: absolute;
    bottom: 0;
    left: 0;
    height: inherit;
    overflow: auto;
    overflow-x: hidden;
  }
}
</style>
