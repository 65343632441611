<template>
  <div class="chat-window d-flex flex-column flex-grow-1">
    <chat-body
      ref="body"
      class="chat-window__body"
      :class="{ 'chat-window__body--employee': isEmployee }"
      :messages="messages"
      :chat-id="chatId"
      @fetch-messages="fetchMessages"
    >
      <base-missing-data v-if="!canDisplayChat" class="flex-grow-1" :with-controls="isEmployee">
        <template #icon>
          <v-icon
            class="chat-window__missing-icon"
            :class="{ 'chat-window__missing-icon--unactive': isNoChats }"
            size="34"
          >
            mdi-email
          </v-icon>
        </template>

        <template #message>
          <span v-if="!isEmployee" class="chat-window__client-missing-message text-body-1 font-weight-light mt-3">
            {{ $t('chat.client_missing_message') }}
          </span>
          <span v-else-if="isNoChats">
            {{ $t('chat.employee_chats_missing_message') }}
          </span>
          <span v-else class="chat-window__employee-missing-message text-body-1 mt-3">
            {{ $t('chat.employee_missing_message') }}
          </span>
        </template>
      </base-missing-data>
    </chat-body>

    <chat-input
      :files="files"
      class="ml-0"
      :class="{ 'ml-md-8': isEmployee }"
      :has-active-chat="hasActiveChat"
      @send="send"
      @upload-file="uploadFile"
      @remove-file="removeFile"
    />
  </div>
</template>

<script>
// Components
import ChatBody from '@/components/Chat/Body.vue';
import ChatInput from '@/components/Chat/Input.vue';
import BaseMissingData from '@/components/BaseMissingData.vue';

// Constants
import { MANAGEMENT_COMPANY_EMPLOYEE } from '@/constants/roles';

export default {
  name: 'ChatWindow',

  components: { ChatBody, ChatInput, BaseMissingData },

  props: {
    messages: { type: Array, default: () => [] },
    files: { type: Array, default: () => [] },
    isNoChats: { type: Boolean, default: false },
    isChatSelected: { type: Boolean, default: false },
    chatId: { type: Number, default: 0 },
    error: { type: String, default: '' },
  },

  computed: {
    hasActiveChat() {
      return Boolean(this.chatId) || this.chatId === 0;
    },

    isEmployee() {
      return this.$store.state.user.role === MANAGEMENT_COMPANY_EMPLOYEE;
    },

    canDisplayChat() {
      return this.messages.length > 0 && this.isChatSelected;
    },
  },

  methods: {
    scrollToBottom() {
      this.$refs.body.scrollToBottom();
    },

    fetchMessages($state) {
      this.$emit('fetch-messages', $state);
    },

    send(data) {
      this.$emit('send', data);
    },

    uploadFile(files) {
      this.$emit('upload-file', files);
    },

    removeFile(fileId) {
      this.$emit('remove-file', fileId);
    },
  },
};
</script>

<style lang="scss">
.chat-window {
  height: 100%;
  position: relative;

  &__body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;

    @media (max-width: map.get($--screens, 'sm')) {
      min-height: 340px;
      width: 100vw;
      margin-left: -8px;
    }
  }

  &__missing-icon {
    color: $--primary-color !important;

    &--unactive {
      opacity: 0.4;
      color: $--black-color-0 !important;
    }
  }

  &__employee-missing-message,
  &__client-missing-message {
    color: $--black-color-0 !important;
  }

  &__client-missing-message {
    width: 245px;
  }
}
</style>
