<template>
  <svg width="16" height="16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#a)">
      <path
        d="M16 8.324c-.07.329-.116.665-.22.986a4.18 4.18 0 0 1-3.946 2.881c-2.54.008-5.081.013-7.622 0C1.81 12.18-.083 10.154.037 7.76.141 5.705 1.767 3.999 3.81 3.8c.154-.016.312-.025.465-.025 2.495 0 4.99-.004 7.48 0 2.055.004 3.755 1.397 4.167 3.41.03.15.054.303.079.457v.682ZM6.915 4.711c.054.05.079.075.108.1.831.736 1.326 1.65 1.434 2.765.034.316.017.636.075.948.32 1.763 1.876 2.91 3.655 2.715 1.564-.175 2.82-1.53 2.873-3.098a3.274 3.274 0 0 0-3.293-3.422c-1.563-.004-3.127 0-4.69 0-.042-.008-.083-.008-.162-.008Zm2.2 6.549a3.034 3.034 0 0 1-.105-.1c-.831-.736-1.326-1.65-1.438-2.761-.034-.316-.017-.636-.075-.948-.337-1.855-2.046-3.023-3.9-2.678C1.884 5.093.69 6.836 1.019 8.55c.312 1.613 1.63 2.707 3.272 2.71h4.823Z"
      />
      <path
        d="M11.318 7.984h-.923v-.932h.919v-.927h.935v.919h.928v.935h-.907c-.058.595-.066 1.052.903.944v.92c-.5.04-.982.02-1.39-.35-.398.366-.88.387-1.392.353V8.92h.382c.362 0 .54-.183.54-.549.01-.124.005-.245.005-.386ZM5.634 9.613h-.927V8.7h-.932v.918h-.927c-.004-.025-.012-.05-.012-.074 0-.765-.009-1.526.004-2.291A1.402 1.402 0 0 1 4.004 5.9c.682-.108 1.33.27 1.555.915.05.141.08.291.083.44.009.757.005 1.518.005 2.275-.005.025-.009.05-.013.083Zm-.927-1.87c0-.175.004-.33 0-.483a.462.462 0 0 0-.424-.44c-.237-.022-.466.132-.495.37-.025.178-.004.365-.004.552h.923Z"
      />
    </g>
    <defs>
      <clipPath id="a"><path d="M0 0h16v16H0z" /></clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconTranslate',
};
</script>
