<template>
  <div class="chat-list d-flex flex-column">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ChatList',
};
</script>
