<template>
  <div class="d-flex align-center px-0 px-md-4 py-3">
    <v-text-field
      v-model="userSearchModel"
      class="chat-search__search"
      prepend-inner-icon="mdi-magnify"
      :placeholder="$t('chat.search')"
      hide-details
    />

    <v-menu
      v-model="menu"
      bottom
      :close-on-content-click="false"
      :attach="mobile ? '.chat-list' : '.chat__sidebar'"
      content-class="chat-search"
    >
      <template #activator="{ on, attrs }">
        <icon-button color="primary" class="ml-2" v-bind="attrs" v-on="on">
          <v-icon>mdi-plus</v-icon>
        </icon-button>
      </template>

      <v-list class="chat-search__list">
        <v-list-item class="chat-search__search-item px-2">
          <v-text-field
            v-model="query"
            autofocus
            class="chat-search__search-field mb-2"
            prepend-inner-icon="mdi-magnify"
            hide-details
            :placeholder="$t('chat.search')"
          />
        </v-list-item>

        <template v-if="isLoading">
          <v-list-item v-for="(_, index) in 5" :key="index" class="px-2">
            <v-list-item-avatar color="#E6EBFF">
              <v-skeleton-loader type="avatar" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-skeleton-loader type="text" />
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-else>
          <v-list-item v-for="user in users" :key="user.id" class="px-2" @click="selectUser(user.id)">
            <v-list-item-avatar color="#E6EBFF">
              <v-icon color="#002FFF">mdi-account</v-icon>
            </v-list-item-avatar>
            <v-list-item-content class="d-flex flex-column align-start">
              <span class="text-caption font-weight-medium"> {{ user.firstName }} {{ user.lastName }} </span>
              <span class="chat-search__units-list text-caption">
                {{ getUnits(user.units) }}
              </span>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
// Http
import client from '@/http/client';

// Services
import { fetchClients } from '@/services/select';

// Utils
import { debounce } from '@/utils/delay';
import { flushPromises } from '@/utils/scheduler';

// Components
import IconButton from '@/components/IconButton.vue';

export default {
  name: 'ChatSearch',

  components: {
    IconButton,
  },

  props: {
    userSearch: { type: String, default: '' },
    mobile: { type: Boolean, default: false },
  },

  data() {
    return {
      limit: 100,
      menu: false,
      users: [],
      query: '',
      isLoading: false,
    };
  },

  computed: {
    userSearchModel: {
      get() {
        return this.userSearch;
      },

      set(value) {
        this.$emit('update:user-search', value);
      },
    },
  },

  watch: {
    query() {
      this.debounceSearch();
    },
  },

  mounted() {
    this.search();
  },

  methods: {
    getUnits(units) {
      return units.map(unit => unit.name).join(', ');
    },

    selectUser(userId) {
      this.$emit('select-user', { userId });
      this.menu = false;
    },

    async search() {
      if (this.$options.cancelSource) {
        this.$options.cancelSource.cancel();
        await flushPromises();
      }

      this.isLoading = true;

      try {
        const cancelSource = client.getCancelToken();
        this.$options.cancelSource = cancelSource;

        const { results } = await fetchClients(
          {
            limit: this.limit,
            offset: 0,
            search: this.query,
            isChatStarted: false,
          },
          { cancelToken: cancelSource.token }
        );

        this.users = results;
      } finally {
        this.isLoading = false;
        this.$options.cancelSource = null;
      }
    },

    // eslint-disable-next-line func-names
    debounceSearch: debounce(function() {
      this.search();
    }),
  },

  cancelSource: null,
};
</script>

<style lang="scss">
$chat-top-and-bottom-indent: 174px;

.chat-search {
  top: 8px !important;
  left: 8px !important;
  min-width: calc(100% - 16px) !important;
  max-height: calc(100% - 16px);

  @media (max-width: map.get($--screens, 'sm')) {
    max-height: calc(100vh - #{$chat-top-and-bottom-indent});
    top: 74px !important;
  }

  &__list {
    padding-top: 0;
    padding-bottom: 0;

    .v-list-item {
      cursor: pointer;

      .v-avatar {
        margin-right: 8px !important;
      }

      &:not(.chat-search__search-item):hover {
        background-color: $--blue-color-60;

        .v-avatar {
          background-color: $--white-color-0 !important;
        }

        .v-list-item__content {
          color: $--blue-color-20;
        }
      }
    }
  }

  &__search-field {
    padding-top: 0;
    margin-top: 3px;

    .v-input__prepend-inner {
      margin-top: 2px !important;
      padding-top: 15px;
      padding-bottom: 13px;
      padding-left: 14px;
    }
  }

  &__search-button {
    .v-divider {
      border-color: rgba($--blue-color-20, 0.15) !important;
    }

    .v-icon {
      color: rgba($--blue-color-20, 0.5);
    }
  }

  &__search-button-content {
    color: rgba($--blue-color-20, 0.5);
  }

  &__search {
    margin-top: 0;
    padding-top: 0;

    .v-input__icon.v-input__icon--prepend-inner {
      padding-top: 13px !important;
      padding-bottom: 13px !important;
      margin-left: 14px;
      width: 24px;
      height: 50px;
    }
  }

  &__units-list {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
  }
}
</style>
